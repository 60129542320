
import { defineComponent, nextTick } from 'vue';
import RoomWidget from '../components/RoomWidget.vue'
import { getRooms, ParsedItem} from '../lib/Contentful'

interface RoomsData {
  rooms: ParsedItem[];
  location: string;
}

export default defineComponent({
  name: 'RoomsHome',
  data(): RoomsData {
    return {
      rooms: [],
      location: window.location.pathname.split("/")[1]
    }
  },
  components: {
    RoomWidget
  },
  async mounted() {
    await this.getRooms(this.location);
  },
  methods: {
    async getRooms(location: string) {
      this.rooms = await getRooms(location);
    }
  }
});

import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("img", {
          src: _ctx.image.image.file.url,
          class: "mx-auto d-block"
        }, null, 8, ["src"])
      ]))
    : _createCommentVNode("", true)
}
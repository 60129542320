
import { defineComponent } from 'vue';

interface RoomWidgetData {
  location: string;
}

export default defineComponent({
  name: 'RoomWidget',
  data(): RoomWidgetData {
    return {
      location: window.location.pathname.split("/")[1]
    }
  },
  props: ['room']
});

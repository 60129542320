<template>
  <div class="section-content section-content--secondary">
    <div class="container">
      <div class="section-heading section-heading--divider-top">
        <h2 class="section-heading__title" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">Ready For The Escape?</h2>
      </div>

      <div class="text-center mb-3" data-aos="fade-up" data-aos-duration="600" data-aos-delay="400">
        <a href="/middlesbrough/booking" class="btn btn-primary btn-lg">Book Your Escape @ Middlesbrough</a>
      </div>

      <div class="text-center mb-3" data-aos="fade-up" data-aos-duration="600" data-aos-delay="400">
        <a href="/hexham/booking" class="btn btn-primary btn-lg">Book Your Escape @ Hexham</a>
      </div>
    </div>
  </div>
</template>
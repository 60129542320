import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_location = _resolveComponent("select-location")
  const _component_main_slider = _resolveComponent("main-slider")
  const _component_banner = _resolveComponent("banner")
  const _component_rooms_home = _resolveComponent("rooms-home")
  const _component_how_it_works = _resolveComponent("how-it-works")
  const _component_testimonials = _resolveComponent("testimonials")
  const _component_get_in_touch = _resolveComponent("get-in-touch")
  const _component_call_to_action = _resolveComponent("call-to-action")
  const _component_call_to_action_landing = _resolveComponent("call-to-action-landing")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.isLanding)
      ? (_openBlock(), _createBlock(_component_select_location, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLanding)
      ? (_openBlock(), _createBlock(_component_main_slider, {
          key: 1,
          location: _ctx.location
        }, null, 8, ["location"]))
      : _createCommentVNode("", true),
    _createVNode(_component_banner),
    (!_ctx.isLanding)
      ? (_openBlock(), _createBlock(_component_rooms_home, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_how_it_works),
    _createVNode(_component_testimonials),
    (!_ctx.isLanding && _ctx.contact)
      ? (_openBlock(), _createBlock(_component_get_in_touch, {
          key: 3,
          address: _ctx.contact.address,
          phone: _ctx.contact.phone,
          email: _ctx.contact.email
        }, null, 8, ["address", "phone", "email"]))
      : _createCommentVNode("", true),
    (!_ctx.isLanding)
      ? (_openBlock(), _createBlock(_component_call_to_action, {
          key: 4,
          location: _ctx.location
        }, null, 8, ["location"]))
      : _createCommentVNode("", true),
    (_ctx.isLanding)
      ? (_openBlock(), _createBlock(_component_call_to_action_landing, { key: 5 }))
      : _createCommentVNode("", true)
  ]))
}
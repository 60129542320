import "core-js/stable";
import "regenerator-runtime/runtime";
import * as contentful from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export interface ParsedItem {
  [key: string]: any;
}

const client = contentful.createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "aofh5t0xnpi5",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "3nO65iCj9JJTt6L5SVtFkrgp2xndyFCa24NtHp8uG3o"
});

const parseItem = (entry: contentful.Entry<any>) => {
  if (!entry.fields) return {};
  const obj: ParsedItem = {}
  const entries: [string, any][] = Object.entries(entry.fields)
  for (const [field, value] of entries) {
    if (value.nodeType === 'document') {
      obj[field] = documentToHtmlString(value)
    }
    else if (['image', 'imageWebp', 'section1Image', 'file', 'file2', 'urlImage', 'urlImage2'].includes(field)) {
      obj[field] = value.fields;
    }
    else {
      obj[field] = value
    }
  }
  return obj;
}

export const getRooms = async (location: string): Promise<ParsedItem[]> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'room',
      'fields.location': location,
      order: 'fields.sortOrder'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items
  } catch (e) {
    console.error('getRooms', (e as any).message)
    return []
  }

}

export const getRoom = async(room: string): Promise<ParsedItem> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'room',
      'fields.url': room
    })
    /* eslint-enable */
    
    const items = raw.items.map(parseItem)
    if (items.length !== 1) throw new Error(`Invalid room URL specified: ${room}`)
    return items[0]
  } catch (e) {
    console.error('getRoom', (e as any).message)
    return []
  }

}

export const getTestimonials = async (): Promise<ParsedItem[]> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'testimonial'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items
    
  } catch (e) {
    console.error('getTestimonials', (e as any).message)
    return []
  }

}

export const getContact = async (location: string): Promise<ParsedItem> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'contact',
      'fields.location': location
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    if (items.length !== 1) throw new Error(`Invalid location specified: ${location}`)
    const contact = items[0]
    contact.address = contact.address.split("\n")
    return contact
  } catch (e) {
    console.error('getContact', (e as any).message);
    return [];
  }

}

export const getAllContacts = async (): Promise<ParsedItem[]> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'contact'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem).map(item => {
      item.address = item.address.split("\n");
      return item;
    })
    
    return items
  } catch (e) {
    console.error('getAllContacts', (e as any).message);
    return [];
  }

}

export const getFAQs = async (): Promise<ParsedItem[]> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'faq',
      order: 'sys.createdAt'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items
    
  } catch (e) {
    console.error('getFAQs', (e as any).message)
    return []
  }
}

export const getAllStaff = async (): Promise<ParsedItem[]> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'staffProfile',
      order: 'sys.createdAt'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items
    
  } catch (e) {
    console.error('getStaff', (e as any).message)
    return []
  }
}

export const getStaffProfile = async(url: string): Promise<ParsedItem> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'staffProfile',
      'fields.url': url
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    if (items.length !== 1) return null;
    return items[0]
    
  } catch (e) {
    console.error('getStaffProfile', (e as any).message)
    return []
  }
}

export const getDownloads = async (): Promise<ParsedItem[]> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'download',
      order: '-sys.createdAt'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items
    
  } catch (e) {
    console.error('getDownloads', (e as any).message)
    return []
  }
}

export const getGameCompleted = async (): Promise<ParsedItem> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'gameCompletedPage',
      order: '-sys.createdAt'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items[0] || null
    
  } catch (e) {
    console.error('getGameCompleted', (e as any).message)
    return []
  }
}

export const getBlogs = async (): Promise<ParsedItem[]> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'blog',
      order: '-sys.createdAt'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    return items
    
  } catch (e) {
    console.error('getBlogs', (e as any).message)
    return []
  }
}

export const getBlogPost = async(url: string): Promise<ParsedItem> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'blog',
      'fields.url': url
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    if (items.length !== 1) return null;
    return items[0]
    
  } catch (e) {
    console.error('getBlogPost', (e as any).message)
    return []
  }
}

export const getBannerImage = async (location: string): Promise<ParsedItem> => {
  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'banner',
      'fields.location': location
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem)
    if (items.length !== 1) return null;
    return items[0]
    
  } catch (e) {
    console.error('getBannerImage', (e as any).message)
    return []
  }
}

export const getJobs = async (): Promise<ParsedItem[]> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'job'
    })
    /* eslint-enable */

    const items = raw.items.map(parseItem).map(item => {
      item.visible = false;
      return item;
    })
    
    return items
  } catch (e) {
    console.error('getJobs', (e as any).message);
    return [];
  }

}

export const getCorporate = async (): Promise<ParsedItem[]> => {

  try {
    /* eslint-disable */
    const raw = await client.getEntries({
      content_type: 'corporate',
      order: 'fields.sortPosition'
    })
    /* eslint-enable */
    const items = raw.items.map(parseItem)
    return items
  } catch (e) {
    console.error('getCorporate', (e as any).message)
    return []
  }

}

import { defineComponent, nextTick } from 'vue';
import HowItWorks from '../components/HowItWorks.vue'
import CallToAction from '../components/CTA.vue'
import CallToActionLanding from '../components/CTALanding.vue'
import GetInTouch from '../components/GetInTouch.vue'
// import GetInTouchLanding from '../components/GetInTouchLanding.vue'
import MainSlider from '../components/MainSlider.vue'
import { ParsedItem, getContact, getAllContacts } from '../lib/Contentful'
import Testimonials from '../components/Testimonials.vue';
import RoomsHome from '../components/RoomsHome.vue';
import Banner from '../components/Banner.vue'
import SelectLocation from '../components/SelectLocation.vue'

interface HomeData {
  contact: ParsedItem;
  contacts: ParsedItem[];
  location: string;
  isLanding: boolean;
}

export default defineComponent({
  data(): HomeData {
    return {
      contact: null,
      contacts: [],
      location: window.location.pathname.split("/")[1],
      isLanding: window.location.pathname === '/',
    }
  },
  async mounted() {
    if (!this.isLanding) {
      await this.getContact(this.location)
    } else {
      await this.getAllContacts()
    }
    nextTick(() => {
      const w = window as any;
      w.fbq('track', 'ViewContent');
      // setTimeout(() => {
        w.initHeader();
      // }, 500);
    })
  },
  methods: {
    async getContact(location: string) {
      this.contact = await getContact(location);
    },
    async getAllContacts() {
      this.contacts = await getAllContacts()
    }
  },
  name: 'Home',
  components: {
    HowItWorks,
    Testimonials,
    CallToAction,
    CallToActionLanding,
    GetInTouch,
    // GetInTouchLanding,
    MainSlider,
    RoomsHome,
    Banner,
    SelectLocation,
  }
});


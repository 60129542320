
import { defineComponent, nextTick } from 'vue';

export default defineComponent({
  name: 'GetInTouch',
  props: ['address', 'phone', 'email', 'description'],
  mounted() {
    nextTick(function() {
      const w = window as any;
      w.google_maps_lazyload("AIzaSyD7zbLN_9dVI40euYL3qt2hJ1WLwpkWsl0")
    })
  }
});


import { defineComponent } from 'vue';
import { getBannerImage, ParsedItem } from '../lib/Contentful'

interface BannerData {
  image: ParsedItem;
  location: string;
}

export default defineComponent({
  name: 'Banner',
  data(): BannerData {
    return {
      image: null,
      location: window.location.pathname.split("/")[1]
    }
  },
  async mounted() {
    await this.getBannerImage(this.location);
  },
  methods: {
    async getBannerImage(location: string) {
      this.image = await getBannerImage(location);
    }
  }
});

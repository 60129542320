
import { defineComponent, nextTick } from 'vue';
import {getContact, ParsedItem, getAllContacts} from './lib/Contentful'

interface Link {
  label: string;
  url: string;
  icon?: string;
}

interface OpeningTime {
  name: string;
  time: string;
}

interface AppData {
  contact: ParsedItem;
  contacts: ParsedItem[];
  footerLinks: Link[];
  days: OpeningTime[];
  social: Link[];
  isLanding: boolean;
  location: string;
}

const location = window.location.pathname.split("/")[1] || null

export default defineComponent({
  name: 'App',
  data(): AppData {
    return {
      isLanding: window.location.pathname === '/',
      location: location,
      contact: {
        address: [],
        phone: '',
        email: '',
        facebook: '',
        instagram: '',
        tripadvisor: ''
      },
      contacts: [],
      footerLinks: [
        { label: 'DOWNLOADS', url: '/downloads'},
        { label: 'ABOUT', url: '/about'},
        { label: 'PLAN AN EVENT', url: '/contact'},
        { label: 'FAQ', url: '/about'},
        { label: 'CONTACT', url: '/contact'},
        { label: 'JOBS', url: '/jobs'},
        { label: 'PRIVACY POLICY', url: '/privacy'}
      ],
      days: [
        { name: 'Monday', time: '12pm - 9pm' },
        { name: 'Tuesday', time: '12pm - 9pm' },
        { name: 'Wednesday', time: '12pm - 9pm' },
        { name: 'Thursday', time: '12pm - 9pm' },
        { name: 'Friday', time: '12pm - 9pm' },
        { name: 'Saturday', time: '9am - 9pm' },
        { name: 'Sunday', time: '9am - 9pm' }
      ],
      social: [
        { label: 'Facebook', url: 'https://www.facebook.com/ProjectEscapeUK', icon: 'fa-facebook-f' },
        { label: 'Instagram', url: 'https://www.instagram.com/projectescapeuk', icon: 'fa-instagram' },
        { label: 'TripAdvisor', url: 'https://www.tripadvisor.co.uk/Attraction_Review-g186340-d12419077-Reviews-Project_Escape-Middlesbrough_North_Yorkshire_England.html', icon: 'fa-tripadvisor' }
      ]
    }
  },
  async mounted() {
    nextTick(async () => {
      const locations = ['middlesbrough', 'hexham']
      if (!this.isLanding && locations.includes(this.location)) {
        await this.getContact(this.location)
      } else {
        await this.getAllContacts()
      }
    })
  },
  methods: {
    async getContact(location: string) {
      this.contact = await getContact(location);
    },
    async getAllContacts() {
      this.contacts = await getAllContacts()
    }
  }
});

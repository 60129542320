import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "testimonial" }
const _hoisted_2 = { class: "testimonial__txt" }
const _hoisted_3 = { class: "testimonial__meta" }
const _hoisted_4 = { class: "testimonial__author-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("blockquote", _hoisted_2, _toDisplayString(_ctx.text.replace(/\u00a0/g, " ")), 1),
    _createVNode("div", _hoisted_3, [
      _createVNode("h6", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.name) + " / ", 1),
        _createVNode("a", {
          target: "_blank",
          href: _ctx.url
        }, _toDisplayString(_ctx.website), 9, ["href"])
      ])
    ])
  ]))
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const start = async () => {

  createApp(App).use(await router()).mount('#app')

}

start()


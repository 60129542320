
import { defineComponent, nextTick } from 'vue';
import Testimonial from './Testimonial.vue'
import { getTestimonials, ParsedItem} from '../lib/Contentful'

interface TestimonialData {
  testimonials: ParsedItem[];
}

export default defineComponent({
  name: 'Testimonials',
  data(): TestimonialData {
    return {
      testimonials: []
    }
  },
  components: {
    Testimonial
  },
  async mounted() {
    await this.getTestimonials();
    nextTick(() => {
      const w = window as any;
      setTimeout(() => {
        w.initTestimonialsSlider();
      }, 500);
    })
  },
  methods: {
    async getTestimonials() {
      this.testimonials = await getTestimonials();
    }
  }
});

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routerFactory = async () => {

  const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'Landing',
      component: Home
    },
    {
      path: '/:location',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('../views/About.vue')
    },
    {
      path: '/:location/rooms',
      name: 'Rooms',
      component: () => import('../views/Rooms.vue')
    },
    {
      path: '/:location/rooms/:room',
      name: 'Room',
      component: () => import('../views/Room.vue')
    },
    {
      path: '/:location/booking',
      name: 'Booking',
      component: () => import('../views/Booking.vue')
    },
    {
      path: '/:location/vouchers',
      name: 'Vouchers',
      component: () => import('../views/Vouchers.vue')
    },
    {
      path: '/:location/voucher-thanks',
      name: 'VouchersThanks',
      component: () => import('../views/VouchersThanks.vue')
    },
    {
      path: '/:location/thanks-for-booking',
      name: 'BookingThanks',
      component: () => import('../views/BookingThanks.vue')
    },
    {
      path: '/corporate',
      name: 'Corporate',
      component: () => import('../views/Corporate.vue')
    },
    {
      path: '/:location/contact',
      name: 'Contact',
      component: () => import('../views/Contact.vue')
    },
    {
      path: '/meet-the-team',
      name: 'Team',
      component: () => import('../views/Team.vue')
    },
    {
      path: '/meet-the-team/:name',
      name: 'StaffProfile',
      component: () => import('../views/StaffProfile.vue')
    },
    {
      path: '/blogs',
      name: 'Blogs',
      component: () => import('../views/Blogs.vue')
    },
    {
      path: '/blogs/:url',
      name: 'BlogPost',
      component: () => import('../views/BlogPost.vue')
    },
    {
      path: '/jobs',
      name: 'Jobs',
      component: () => import('../views/Jobs.vue')
    },
    {
      path: '/downloads',
      name: 'Downloads',
      component: () => import('../views/Downloads.vue'),
    },
    {
      path: '/privacy',
      name: 'PrivacyPolicy',
      component: () => import('../views/PrivacyPolicy.vue')
    },
    {
      path: '/app/:name/completed',
      name: 'GameCompleted',
      component: () => import('../views/GameCompleted.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../views/NotFound.vue')
    }
  ]
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  return router;
  
  
}

export default routerFactory



import { defineComponent, nextTick } from 'vue';

export default defineComponent({
  name: 'MainSlider',
  props: ['location'],
  async mounted() {
    nextTick(function() {
      setTimeout(() => {
        const w = window as any;
        w.initMainSlider();
      }, 500)
      
    })
  }
});
